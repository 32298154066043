<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Liste des Balises">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-add-balise
            variant="outline-primary"
          >
            Ajouter
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="genaratePdf"
          >
            Generer PDF
          </b-button> -->
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="balisecolumns"
            :rows="balises"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editBalise(props.row)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Modifier</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!--    Modal Add-->
          <b-modal
            id="modal-add-balise"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            centered
            :ok-disabled="desableButton"
            :cancel-disabled="desableButton"
            size="lg"
            title="Ajouter une Balise"
            @ok="addBalise"
          >
            <b-overlay
              :show="showOverlay"
              spinner-variant="primary"
              spinner-type="grow"
              label="Spinning"
              spinner-large
              rounded="sm"
            >
              <validation-observer ref="simpleRules">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Hameau"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.hameau"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Hameau"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Longitude"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.longitude"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Longitude"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Latitude"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.latitude"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Latitude"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Departement"
                          vid="departement"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.departement"
                            :options="departements"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Commune"
                          vid="commune"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.commune"
                            :options="communes"
                            :disabled="balise.departement==null? true:false"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Arrondissement"
                          vid="arrondissement"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.arrondissement"
                            :disabled="balise.commune==null? true:false"
                            :options="arrondissements"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Zone"
                          vid="zone"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.zone"
                            :disabled="balise.arrondissement==null? true:false"
                            :options="zones"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-form>
              </validation-observer>

            </b-overlay>

          </b-modal>
          <!--    Modal Edit-->
          <b-modal
            id="modal-edit-balise"
            cancel-variant="outline-secondary"
            ok-title="Mettre à jour"
            cancel-title="Close"
            centered
            :ok-disabled="desableButton"
            :cancel-disabled="desableButton"
            size="lg"
            title="Modifier une Balise"
            @ok="updateBalise"
          >
            <b-overlay
              :show="showOverlay"
              spinner-variant="primary"
              spinner-type="grow"
              label="Spinning"
              spinner-large
              rounded="sm"
            >
              <validation-observer ref="simpleRules">
                <b-form>
                  <b-row>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Hameau"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.hameau"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Hameau"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Longitude"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.longitude"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Longitude"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Latitude"
                          rules="required"
                        >
                          <b-form-input
                            v-model="balise.latitude"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Latitude"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Departement"
                          vid="departement"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.departement"
                            :options="departements"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Commune"
                          vid="commune"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.commune"
                            :options="communes"
                            :disabled="balise.departement==null? true:false"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Arrondissement"
                          vid="arrondissement"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.arrondissement"
                            :disabled="balise.commune==null? true:false"
                            :options="arrondissements"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="6"
                      lg="6"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Zone"
                          vid="zone"
                          rules="required"
                        >
                          <b-form-select
                            v-model="balise.zone"
                            :disabled="balise.arrondissement==null? true:false"
                            :options="zones"
                            :state="errors.length > 0 ? false:null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-form>
              </validation-observer>

            </b-overlay>

          </b-modal>
          -->
        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BForm, BModal, BFormSelect, BRow, BCol, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import jsPDF from 'jspdf'
import baliseMixin from '../@core/mixins/baliseMixin'
import 'jspdf-autotable'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [baliseMixin],
  data() {
    return {
      required,
      pageLength: 50,
      dir: false,
      showOverlay: true,
      desableButton: false,
      zones: [
        { value: null, text: 'Veuillez sélectionner un village ou quartier' },
      ],
      arrondissements: [
        { value: null, text: 'Veuillez sélectionner un arrondissement' },
      ],
      communes: [
        { value: null, text: 'Veuillez sélectionner une commune' },
      ],
      departements: [
        { value: null, text: 'Veuillez sélectionner un departement' },
      ],
      balisecolumns: [
        {
          label: 'Hameau',
          field: 'hameau',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Village',
          field: 'zone.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Arrondissement',
          field: 'arrondissement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Commune',
          field: 'commune.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Departement',
          field: 'departement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Longitude',
          field: 'longitude',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Longitude',
          },
        },
        {
          label: 'Latitude',
          field: 'latitude',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par nom',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      balise: {
        hameau: null,
        zone: null,
        arrondissement: null,
        commune: null,
        departement: null,
        longitude: null,
        latitude: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    'balise.departement': function (newValue) {
      this.balise.commune = null
      this.getCommuneList(newValue)
    },
    'balise.commune': function (newValue) {
      this.balise.arrondissement = null
      this.getArrondissementList(newValue)
    },
    'balise.arrondissement': function (newValue) {
      this.balise.zone = null
      this.getZoneList(newValue)
    },

  },
  async created() {
    try {
      await this.getDepartementList()
    } catch (error) {
      console.log(error.response)
    }
    // this.$store.dispatch('mainAppStore/fetchZonesListItems').then(response => {
    //   console.log(response.data)
    //   // eslint-disable-next-line no-plusplus
    //   for (let index = 0; index < response.data.length; index++) {
    //     const zone = {
    //       value: response.data[index].id,
    //       text: response.data[index].name,
    //     }
    //     this.zones.push(zone)
    //   }
    // })
    //   .catch(err => {
    //     console.error(err.response.headers)
    //   })
  },
  methods: {
    genaratePdf() {
      const headers = []
      this.balisecolumns.forEach(element => {
        headers.push(element.label)
      })
      const rows = []
      this.balises.forEach(element => {
        const record = []

        record.push(element.hameau)
        record.push(element.zone.name)
        record.push(element.arrondissement.name)
        record.push(element.commune.name)
        record.push(element.departement.name)
        record.push(element.longitude)
        record.push(element.latitude)
        console.log(record)
        rows.push(record)
      })
      console.log(rows)
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()
      doc.autoTable({
        head: [headers.slice(0, -1)],
        body: rows,
      })

      doc.save('table.pdf')
    },
    async getDepartementList() {
      this.showOverlay = true
      const response = await this.$store.dispatch('mainAppStore/fetchDepartementsListItems')
      console.log(response.data)
      if (response.data.length > 0) {
        for (let index = 0; index < response.data.length; index++) {
          const departement = {
            value: response.data[index].id,
            text: response.data[index].name,
          }
          this.departements.push(departement)
        }
        this.showOverlay = false
      }
    },
    async getCommuneList(departement) {
      this.showOverlay = true
      this.communes = [
        { value: null, text: 'Veuillez sélectionner une commune' },
      ]

      const response = await this.$store.dispatch('mainAppStore/fetchCommunesListItems')
      if (response.data.length > 0) {
        for (let index = 0; index < response.data.length; index++) {
           console.log(response.data[index].departement)
          if (response.data[index].departement._id === departement) {
            const commune = {
              value: response.data[index].id,
              text: response.data[index].name,
            }
            console.log("ajout commune")
            this.communes.push(commune)
          }
        }
        this.showOverlay = false
      }
    },
    async getArrondissementList(commune) {
      this.showOverlay = true
      this.arrondissements = [
        { value: null, text: 'Veuillez sélectionner un arrondissement' },
      ]

      try {
        const response = await this.$store.dispatch('mainAppStore/fetchFilteredArrondissementsListItems')
        if (response.data.length > 0) {
          for (let index = 0; index < response.data.length; index++) {
            const test = response.data[index].commune.createdAt
            console.log(test)
            // eslint-disable-next-line no-underscore-dangle
            if (response.data[index].commune === commune) {
              const arrondissement = {
                value: response.data[index].id,
                text: response.data[index].name,
              }
              this.arrondissements.push(arrondissement)
            }
          }
          this.showOverlay = false
        }
      } catch (error) {
        this.showOverlay = false
        console.log(error)
      }
    },
    async getZoneList(arrondissement) {
      this.showOverlay = true
      this.zones = [
        { value: null, text: 'Veuillez sélectionner un village ou quartier' },
      ]

      const response = await this.$store.dispatch('mainAppStore/fetchZonesListItems')
      if (response.data.length > 0) {
      console.log(response.data)
        for (let index = 0; index < response.data.length; index++) {
          if (response.data[index].arrondissement._id === arrondissement) {
            const zone = {
              value: response.data[index].id,
              text: response.data[index].name,
            }
            this.zones.push(zone)
          }
        }
        this.showOverlay = false
      }
    },
    addBalise(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.desableButton = true
          this.$store.dispatch('mainAppStore/addBaliseItem', this.balise).then(response => {
            console.log(response.data)
            this.desableButton = false
            this.$bvModal.hide('modal-add-balise')
            this.$swal({
              title: 'Succes',
              html: 'Sous Balise ajoutée avec succes',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.desableButton = false
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response)
            })
        }
      })
    },
    editBalise(balise) {
      this.balise.id = balise.id
      this.balise.hameau = balise.hameau
      this.balise.longitude = balise.longitude
      this.balise.latitude = balise.latitude
      this.balise.zone = balise.zone
      this.$bvModal.show('modal-edit-balise')
    },
    updateBalise(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('mainAppStore/updateBaliseItem', this.balise).then(response => {
            console.log(response.data)
            this.$bvModal.hide('modal-edit-balise')
            this.$swal({
              title: 'Succes',
              html: `${response.data.hameau} est modifier avec succes`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response.headers)
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
